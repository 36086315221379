import type {
	FactoryWithDependencies,
	Identifier,
	Factory,
	NamedIdentifier,
	MultiIdentifier,
	Dependencies,
	OptionalIdentifier,
} from './types'
import { ModuleMetadataSymbol } from './symbols'

export const withDependencies = <
	T = any,
	Deps extends ReadonlyArray<Dependencies<any>> = ReadonlyArray<Dependencies<any>>
>(
	dependencies: Deps,
	target: Factory<Deps, T>
): FactoryWithDependencies<T, Deps> => {
	return Object.assign(target.bind(null), { [ModuleMetadataSymbol]: { dependencies } })
}

export const getDependencies = <Deps extends ReadonlyArray<Dependencies<any>>>(
	target: FactoryWithDependencies<any, Deps>
) => target[ModuleMetadataSymbol].dependencies

export const named = (identifier: Identifier, name: string): NamedIdentifier => ({
	name,
	identifier,
})

export const isNamed = (identifier: any): identifier is NamedIdentifier => !!(identifier.name && identifier.identifier)

export const multi = (identifier: any): MultiIdentifier => ({
	identifier,
	multi: true,
})

export const isMulti = (identifier: any): identifier is MultiIdentifier => !!(identifier.multi && identifier.identifier)

export const optional = (identifier: any): OptionalIdentifier => ({
	identifier,
	optional: true,
})

export const isOptional = (identifier: any): identifier is OptionalIdentifier =>
	!!(identifier.optional && identifier.identifier)
